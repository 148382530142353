<template>
  <StaticPagePug>
    <div class="form-wrapper">
      <div id="forgetPassword">
        <div class="from-box" v-if="formFlag">
          <div class="title">{{ $t('common.button.forgotPw') }}</div>
          <el-form :model="emailForm" @submit.native.prevent hide-required-asterisk label-position="top" :rules="rule" ref="emailForm">
            <p>{{ $t('forgetPwReq.tip') }}</p>
            <div class="submit-field">
              <el-form-item :label="$t('common.field.emailAdd')" prop="email">
                <el-input
                  v-model.trim="emailForm.email"
                  :placeholder="$t('common.field.emailAdd')"
                  autocomplete="off"
                  name="email"
                ></el-input>
              </el-form-item>
              <el-button class="ok-btn" @click="submitForm('emailForm')" data-testid="sendResetPw">
                {{ $t('common.button.submit') }}
              </el-button>
              <router-link to="/login" class="back">
                {{ $t('common.button.bkToHm') }}
              </router-link>
            </div>
          </el-form>
          <p class="error" v-if="errorFlag">{{ errMsg }}</p>
        </div>
        <div class="result" v-else>
          <div class="title">{{$t('resetPassword.inputLoginEmail')}}</div>
          <p v-html="$t('forgetPwReq.succ')"></p>
          <router-link :to="{ name: 'home' }" class="el-button ok-btn">
            {{ $t('common.button.bkToHm') }}
          </router-link>
        </div>
      </div>
    </div>
  </StaticPagePug>
</template>

<script>
import StaticPagePug from '@/components/template/staticPage/StaticPagePug';
import { apiReq_reset_profile_password } from '@/resource';

export default {
  components: {
    StaticPagePug
  },
  data() {
    return {
      formFlag: true,
      emailForm: {
        email: ''
      },
      rule: {
        email: [
          {
            required: true,
            message: this.$t('forgetPwReq.formValidation.emailReq'),
            trigger: 'blur'
          }
        ]
      },
      errMsg: '',
      errorFlag: false
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.getApiReq_reset_profile_password();
        } else return false;
      });
    },
    getApiReq_reset_profile_password() {
      this.errMsg = '';
      this.errorFlag = false;

      apiReq_reset_profile_password({
        email: this.emailForm.email,
        baseUrl: location.protocol + '//' + location.hostname
      }).then(resp => {
        if (resp.data.code === 0) {
          if (resp.data.data.result == true) {
            this.formFlag = false;
          }
        } else if (resp.data.code === 421) {
          this.errMsg = resp.data.errmsg;
          this.errorFlag = true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/loginForm.scss';
@import '@/assets/css/pages/forgetPasswordReqPug.scss';
</style>
